import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../templates/layout'
import { ArrowLeft, Wave } from '../components/icons/index'
import { SEO, ProjectListItem as Project, Heading } from '../components/index'

const Projects = ({ data }) => {
  const { edges: projects } = data.allMdx
  return (
    <Layout>
      <SEO title={`Projects`} />
      <div className="grid site-top">
        <div className="row pb2-xs pb9-md">
          <div className="col col-12-xs col-0-xs-offset col-8-md col-2-md-offset">
            <div className="row row-xs-middle">
              <Link to="/" style={{ marginRight: '2rem', color: '#F59CC5' }}>
                <ArrowLeft fill="#F59CC5" top="0.4rem" />
              </Link>
              <Heading level="1" size="h1" color="#F59CC5" accent={true} align="left">
                Projects
              </Heading>
            </div>
          </div>
        </div>
        <div>
          {projects.map(({ node: project }, index) => (
            <Project data={project} key={index} index={index} />
          ))}
        </div>
        {projects && projects.length > 1 && (
          <div className="row">
            <div className="col col-12-xs col-0-xs-offset col-8-md col-2-md-offset pt3-xs pt6-md pb2-md">
              <Wave />
            </div>
          </div>
        )}
      </div>
    </Layout>
  )
}
export const pageQuery = graphql`
  query projectIndex {
    allMdx(
      filter: { fileAbsolutePath: { regex: "/projects/" }, frontmatter: { published: { eq: true } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      totalCount
      edges {
        node {
          id
          excerpt
          frontmatter {
            title
            headline
            categories
          }
          fields {
            slug
          }
        }
      }
      group(field: frontmatter___categories) {
        category: fieldValue
        totalCount
      }
    }
  }
`
export default Projects
